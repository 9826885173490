import { useStaticQuery, graphql } from 'gatsby'

export const useAdditionalMenuItems = () =>
  useStaticQuery(graphql`
    query {
      globalHeaderEn: sanityGlobalHeader(i18n_lang: {eq: "en"}) {
        additionalMenu {
          items {
            title
            url
          }
        }
      }
      globalHeaderIt: sanityGlobalHeader(i18n_lang: {eq: "it"}) {
        additionalMenu {
          items {
            title
            url
          }
        }
      }
    }
  `)