import { Link } from 'gatsby'
import React from 'react'
import { motion } from 'framer-motion'

import loadable from '@loadable/component'
import { useHeaderNav } from '../../stores/HeaderNav'
import { useMenuItems } from './MenuItems'
import { useAdditionalMenuItems } from './AdditionalMenuItems'

import Shape from '../../svg/shape-mobileNav.svg'
import MenuBackground from '../../svg/mask-menu.svg'

const PhoneNumber = loadable(() => import('./PhoneNumber'))

const HeaderNavMobile = () => {
  const language = process.env.GATSBY_SITE_LANGUAGE
  const { menuVisible, setMenuVisible } = useHeaderNav()

  const getMenuItems = useMenuItems()
  const additionalMenuItems = useAdditionalMenuItems()
  const headerData = language === 'en' ? getMenuItems?.globalHeaderEn : getMenuItems?.globalHeaderIt  
  

  const { items } = headerData.menu
  
  const additionalItems = language === 'en' ? additionalMenuItems.globalHeaderEn.additionalMenu.items : additionalMenuItems.globalHeaderIt.additionalMenu.items

  return (
    <>
      <MenuBackground className="sr-only" />
      <motion.div
        animate={{
          x: menuVisible ? 0 : 300,
        }}
        className={`mobileNav fixed z-20 text-white bg-opacity-80 h-screen right-0 top-0 pt-32 pl-12 pr-8 ${
          menuVisible ? 'opacity-100' : 'opacity-0'
        }`}
      >
        <Shape
          className="absolute top-0 left-0 h-full text-blue-500"
          style={{ opacity: 0.8, zIndex: -1 }}
        />
        <ul>
          {items.map((page, index) => {
            let link = page.url

            if (link) {
              if (!page.url.endsWith('/')) {
                link = `${page.url}/`
              }
            }
            return (
              <li className="pb-1 text-18" key={index}>
                <Link
                  to={link}
                  className="block py-3"
                  onClick={() => setMenuVisible(false)}
                >
                  {page.title}
                </Link>
              </li>
            )
          })}
        </ul>

        <hr className="mb-12 border-blue-200" />

        <ul>
          {additionalItems.map((page, index) => {
            let link = page.url
            if (!page.url.endsWith('/')) {
              link = `${page.url}/`
            }
            return (
              <li className="pb-1 text-14" key={index}>
                <Link
                  to={link}
                  className="block py-2"
                  onClick={() => setMenuVisible(false)}
                >
                  {page.title}
                </Link>
              </li>
            )
          })}
        </ul>

        <PhoneNumber />
      </motion.div>
    </>
  )
}

export default HeaderNavMobile
